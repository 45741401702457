const legendScroll = function(chart) {
  var legend = chart.legend,
    initialY = legend.options.y,
    legendHeight = legend.titleHeight + legend.legendHeight,
    chartOffsetTop = $(chart.container).offset().top - initialY,
    chartHeight = $(chart.container).outerHeight(true),
    isChartInView;

  $(window).scroll(function() {
    var windowScrollTop = $(window).scrollTop();
    var windowBottom = windowScrollTop + window.innerHeight;
    isChartInView = windowBottom > chartOffsetTop && windowScrollTop < chartOffsetTop + chartHeight;

    if (isChartInView) {
      let max = Math.min(chart.chartHeight - legendHeight, window.scrollY - chartOffsetTop)
      let min = Math.max(0, max)
      legend.group.attr({
        translateY: initialY + min
      });
    }
  })
}

export default legendScroll;