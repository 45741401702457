import { Controller } from "stimulus"
import Highcharts from "highcharts"
import exportConfig from '../export_config';
import defaultHighchartsConfig from "../default_highcharts_config";
require('highcharts/modules/exporting')(Highcharts);

export default class extends Controller {
  connect() {
    let isPDF = this.data.get("format") === "pdf";
    let subtitleText;

    if (JSON.parse(this.data.get("series")).length > 1) {
      subtitleText = 'Hover over dates below to see the FIA results for a specific date';
    } else {
      subtitleText = 'Date:';
    }

    let config = defaultHighchartsConfig.merge({
      colors: ['#f5dfdc', '#f9d643','#70D6D6', '#162359', '#009490'],
      chart: {
        type: 'bar',
      },
      title: {
        text: 'PROPORTION OF ITEMS BY SCORE',
      },
      subtitle: {
        text: subtitleText,
        align: 'left',
      },
      xAxis: {
        categories: [
          'Laying the foundation',
          'Installing',
          'Implementing',
          'Sustaining Implementation',
          'Total Score'
        ],
        labels: {
          style: {
            width: '100px'
          }
        }
      },
      yAxis: {
        min: -0.1,
        startOnTick: false,
      },
      plotOptions: {
        series: {
          animation: !isPDF,
          borderWidth: 0,
          borderColor: "#162359",
          borderWidth: 2,
          colorByPoint: true
        },
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: 'horizontal',
        title: {text: null},
      },
      series: JSON.parse(this.data.get("series")),
      ...exportConfig,
      exporting: {...exportConfig.exporting, enabled: !isPDF},
    })

    // We are delaying the loading of highcharts here because
    // when the data is cached from a previous load highcharts
    // loads too quickly causing the size of the chart to be incorrect.
    // Delaying the load fixes this.
    if (isPDF) {
      Highcharts.chart(this.element, config)
    } else {
      setTimeout(() => {
        Highcharts.chart(this.element, config)
      }, 250)
    }
  }
}
