import { Controller } from "stimulus"
import Highcharts from "highcharts"
import defaultHighchartsConfig from '../default_highcharts_config';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/pattern-fill')(Highcharts);

export default class extends Controller {
  connect() {
    let isPDF = this.data.get("format") === "pdf";

    let config = defaultHighchartsConfig.merge({
      chart: {
        type: isPDF ? 'bar' : 'column',
        height: isPDF ? Math.max(50 * JSON.parse(this.data.get("categories")).length, 400) : 400,
        marginLeft: isPDF ? 200 : undefined,
      },
      title: {
        text: 'Total Mean Score',
      },
      xAxis: {
        categories: JSON.parse(this.data.get("categories")),
      },
      plotOptions: {
        series: {
          animation: !isPDF,
          color: '#162359',
        },
        column: {
          maxPointWidth: 125,
        }
      },
      legend: {
        enabled: false
      },
      series: JSON.parse(this.data.get("series")),
    })

    // We are delaying the loading of highcharts here because
    // when the data is cached from a previous load highcharts
    // loads too quickly causing the size of the chart to be incorrect.
    // Delaying the load fixes this.
    if (isPDF) {
      Highcharts.chart(this.element, config)
    } else {
      setTimeout(() => {
        Highcharts.chart(this.element, config)
      }, 250)
    }
  }
}
