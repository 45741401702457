import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "carousel", "assessmentDate" ]

  connect(){
    $(this.assessmentDateTarget).text($(".active").data("assessment-date"))

    $(this.carouselTarget).on("slide.bs.carousel", (e) => {
      let newDate = $(e.relatedTarget).data("assessment-date")
      $(this.assessmentDateTarget).text(newDate)
    })
  }

  previous(){
    $(this.carouselTarget).carousel("prev")
  }

  next(){
    $(this.carouselTarget).carousel("next")
  }
}