import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect(){
    // Stimulus won't let me use the "slide.bs.carousel" event,
    // so I'm reegistering an event listener here instead.
    $(document).on("slide.bs.carousel", (e) => {
      this.update(e)
    })
  }

  update(e) {
    let target = this.inputTargets.find(inputTarget => inputTarget.name == `assessment_report[${$(e.relatedTarget).data("name")}]`)
    let assessmentId = $(e.relatedTarget).data("assessment-id")
    $(target).val(assessmentId)
  }
}