import { Controller } from "stimulus"
import Highcharts from "highcharts"
import legendScroll from '../legend_scroll';
import defaultHighchartsConfig from "../default_highcharts_config";
require('highcharts/modules/exporting')(Highcharts);

export default class extends Controller {
  connect() {
    let isPDF = this.data.get("format") === "pdf";

    let config = defaultHighchartsConfig.merge({
      chart: {
        height: Math.max((75 * JSON.parse(this.data.get("series")).length), 300),
        type: 'bar',
        marginLeft: isPDF ? 200 : undefined,
      },
      title: {
        text: this.data.get("title"),
        style: {
          color: '#162359',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        categories: JSON.parse(this.data.get("categories")),
      },
      yAxis: {
        min: -0.1,
        startOnTick: false,
      },
      plotOptions: {
        series: {
          animation: !isPDF,
          stacking: true,
          groupPadding: 0,
        },
        bar: {
          dataLabels: {
            inside: false,
            x: 5,
            style: {
              color: "#2F3535"
            }
          },
        }
      },
      legend: {
        enabled: !isPDF
      },
      series: JSON.parse(this.data.get("series")),
    })

    // Because we are treating the x-axis data as individual series, we need to
    // manually configure how the legend items are hidden and shown.
    // https://www.highcharts.com/forum/viewtopic.php?p=114801#p114801
    let originalData = JSON.parse(this.data.get("series"))
    originalData.forEach(series => series.visible = true)
    let originalCategories = JSON.parse(this.data.get("categories")).map(category => ({"name": category, "visible": true}))
    let event = {
      legendItemClick: function (e) {
        let clickedName = e.target.name;

        let data = originalData.find((series) => series.name == clickedName)
        data.visible = !data.visible;
        let category = originalCategories.find((category) => category.name == clickedName)
        category.visible = !category.visible;

        let index = 0
        let currentData = JSON.parse(JSON.stringify(originalData))
        currentData.forEach((series, i) => {
          if (series.visible) {
            series.data[0].x = index
            index++;
          } else {
            series.data = []
          }
        })

        let currentCategories = originalCategories.filter(category => category.visible);

        // Re-render the chart with the current data
        Highcharts.chart(this.chart.renderTo, { ...config, series: currentData, xAxis: { ...config.xAxis, categories: currentCategories } });
        return false; // prevent default behavior
      }
    }

    config['plotOptions']['series']['events'] = event;

    // We are delaying the loading of highcharts here because
    // when the data is cached from a previous load highcharts
    // loads too quickly causing the size of the chart to be incorrect.
    // Delaying the load fixes this.
    if (isPDF) {
      Highcharts.chart(this.element, config)
    } else {
      setTimeout(() => {
        Highcharts.chart(this.element, config, legendScroll)
      }, 250)
    }
  }
}
