import Highcharts from "highcharts"
import _ from "lodash";

class HighchartsConfig {
  constructor() {
    this.defaultConfig = {
      chart: {
        style: {
          fontFamily: 'Montserrat',
          overflow: 'visible' // Set overflow to visible to prevent hiding the download button
        }
      },
      title: {
        align: 'left',
        style: {
          color: '#159490',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        title: {
          text: null,
        },
        lineColor: 'white',
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: null
        },
        labels: {
          format: '{value}%',
          style: {
            fontSize: '12px'
          }
        }
      },
      tooltip: {
        valueSuffix: '%',
        style: {
          fontSize: '12px'
        }
      },
      plotOptions: {
        series: {
          animation: false,
          pointPadding: 0.2,
          groupPadding: 0.1,
          shadow: false,
          borderColor: "#162359",
          borderWidth: 2,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}%',
            allowOverlap: true,
            style: {
              fontSize: '12px'
            }
          },
          states: {
            hover: {
              // by default; highcharts set our brightness to 0.1 which was washing out our #f5dfdc (peach)color
              // https://api.highcharts.com/highcharts/plotOptions.column.states.hover.brightness
              // by setting it to 0.0, the color does not wash out
              brightness: 0.0
            }
          }
        },
        bar: {
          dataLabels: {
            enabled: true
          },
        },
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        y: 30,
        floating: false,
        borderWidth: 0,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        symbolPadding: 0,
        symbolWidth: 0,
        symbolHeight: 0,
        squareSymbol: false,
        width: 200,
        title: {
          text: "Hover over option below to highlight specific bars on the chart. Click on the items in the list below to hide them from the chart.",
          style: {
            fontWeight: 'normal',
          }
        },
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      }
    };
  }

  merge(config) {
    return _.merge({}, this.defaultConfig, config);
  }
}

export default new HighchartsConfig();
