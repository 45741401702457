import { Controller } from "stimulus"
import Highcharts from "highcharts"
import legendScroll from '../legend_scroll'
import defaultHighchartsConfig from "../default_highcharts_config";
require('highcharts-grouped-categories')(Highcharts);

export default class extends Controller {
  connect() {
    let isPDF = this.data.get("format") === "pdf";

    let featureCount = JSON.parse(this.data.get("series"))[0].data.length
    let itemCount = JSON.parse(this.data.get("series")).length

    // each bar should be 40px wide. the number of bars is equal to the featureCount * itemCount
    let height = 40 * (featureCount * itemCount)

    // there should be a separation of 5px between items
    // there should be a separation of 10 between features
    height += 5 * (itemCount - 1) + 10 * (featureCount - 1)

    height = Math.max(height, 300)

    let config = defaultHighchartsConfig.merge({
      chart: {
        height: height,
        type: 'bar',
      },
      title: {
        text: 'Feature Scores',
      },
      xAxis: {
        categories: JSON.parse(this.data.get("categories")),
        tickWidth: 0,
        padding: 15,
        labels: {
          groupedOptions: [{
            style: {
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#2F3535',
            },
          }],
          useHTML: true,
          style: {
            textOverflow: 'wrap',
            width: '150px' //adjust width as needed to control wrapping
          }
        },
      },
      yAxis: {
        min: -0.1,
        startOnTick: false,
      },
      plotOptions: {
        series: {
          animation: !isPDF,
          pointPadding: 0.1,
          groupPadding: 0.03,
          dataLabels: {
            format: '{point.y:.1f}% ({series.name})',
          },
        },
      },
      legend: {
        enabled: !isPDF
      },
      series: JSON.parse(this.data.get("series")),
    })

    // We are delaying the loading of highcharts here because
    // when the data is cached from a previous load highcharts
    // loads too quickly causing the size of the chart to be incorrect.
    // Delaying the load fixes this.
    if (isPDF) {
      Highcharts.chart(this.element, config)
    } else {
      setTimeout(() => {
        Highcharts.chart(this.element, config, legendScroll)
      }, 250)
    }
  }
}
