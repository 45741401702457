import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dropdowns", "textbox", "checkbox" ]

  connect() {
    this.toggleVisibility()
  }

  toggleVisibility() {
    if (this.checkboxTarget.checked) {
      this.showTextBox();
    } else {
      this.showDropdowns();
    }
    this.clearFields();
  }
  
  showTextBox() {
    this.dropdownsTarget.classList.add('d-none');
    this.textboxTarget.classList.remove('d-none');
  }
  
  showDropdowns() {
    this.dropdownsTarget.classList.remove('d-none');
    this.textboxTarget.classList.add('d-none');
  }

  toggle(event) {
    this.toggleVisibility()
  }

  clearFields() {
    // Clear all select elements inside the dropdowns target
    this.dropdownsTarget.querySelectorAll('select').forEach(select => {
      select.value = ""
    })
  }
}